.dragdrop-widget {
  padding: 50px;
  display: flex;
  justify-content: space-between;
}

.box1-dragdrop {
  width: 320px;
  height: 240px;
}

.box2-dragdrop {
  width: 320px;
  height: 240px;
  background: rgb(255, 255, 3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.box3-dragdrop {
  width: 320px !important;
  height: 240px !important;
  border: 0.2rem solid black !important;
  background: lightgreen !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding-left: 25px;
  padding-bottom: 1px;
  font-size: 10px;
}


.boxAnswers {
  width: 100%;
  display: flex;

  padding: 20px 0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.boxAnswers .boxAnswersColumn {
  background-color: #EFEFEF;
  box-flex: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  min-height: 150px;
  margin: 0 0.5%;
  padding: 0 0 40px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.boxAnswers .boxAnswersColumn div.titleBoxAnswer {
  display: block;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  color: #555;
  height: 60px;
  overflow-wrap: break-word;
  font-size: 13px;
}

.boxAnswers .boxAnswersColumn:first-of-type {
  margin: 0 0.5% 0 0;
}

.boxAnswers .boxAnswersColumn:last-of-type {
  margin: 0 0 0 0.5%;
}

/* .spnArrowInd {
   background-image: url("../images/arrow.png");
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  height: 40px;
  display: block;
} */

#boxQuestions {
  border: 2px solid rgb(219, 219, 219);
  padding: 2px;
  border-radius: 8px;
}

.boxQuestions {
  margin: 30px;
  color: rgb(177, 162, 162);
  border: 1px solid black;
  height: 100%;
  font-size: 1.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.boxQuestionsPodium {
  min-height: 100px;
  cursor: pointer;

  font-size: 20px;
  text-decoration: none;
  line-height: 0.9;
  text-align: center;
  color: #FF0066;
  background-color: #EFEFEF;
  width: auto;
  border-radius: 5px;
  -webkit-border-radius: 11px;
  -moz-border-radius: 5px;

  justify-content: center;
  padding: 12px;

  display: flex;
  flex-wrap: wrap;
}

div.spnTitleBoxQuestion {
  display: block;
  font-weight: bold;
  padding: 0 7px;
}

.boxAnswers div.spnBoxQuestions {
  padding: 15px 7px !important;
  margin: 35px 10% 0 !important;
  width: 80% !important;
  overflow-wrap: break-word;
  word-break: break-word;
}

.boxAnswers div.spnMobileBoxQuestions {
  padding: 15px 7px !important;
  margin: 35px 10% 0 !important;
  overflow-wrap: break-word;
  word-break: break-word;
}

.spnBoxQuestions:hover {
  cursor: grab;
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.spnMobileBoxQuestions:hover {
  cursor: grab;
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.dragging {
  opacity: .5 !important;
}

.mobileDragging {
  opacity: .5 !important;
  width: 140px !important;
}

.in-box {
  width: 80% !important;
}

/*
.boxAnswersColumnPodium:nth-child(2){
   background-image: url("https://erhebung.top-service-oesterreich.com/umfrage/images/number-2.png");
  background-size: 70px 70px;
  background-position: center 20px;
  background-repeat: no-repeat;
  background-color: #FFD15C;
}
.boxAnswersColumnPodium:nth-child(1){
  background-color: #66C6B9;
}
.boxAnswersColumnPodium:nth-child(3){
  background-color: #F3705A;
} */

.boxAnswersPodium {
  width: 100%;
  margin: 0;
  /* position: relative; */
  display: flex;
  justify-content: flex-start;

  flex-wrap: nowrap;
  align-items: flex-start;

  min-height: 230px;
  gap: 24px;
}

.boxAnswersColumnPodium {
  float: left;
  position: relative;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 230px;
  flex: 1;
  border-radius: 0 0 12px 12px;
  justify-items: center;

  @media (max-width: 900px) {
    line-height: 0.9em;
  }
}

.podiumWrapper {
  width: 100%;
  margin: 24px 0 0 0;
  /* position: relative; */
  display: flex;
  justify-content: flex-start;

  flex-wrap: nowrap;
  align-items: stretch;
  gap: 24px;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 11px;
  }

}

.podiumNumber {
  min-height: 52px;
  line-height: 34px;
  padding: 0 8px;
  border-radius: 12px 12px 0 0;
  font-size: 28px;
  text-align: center;
  font-weight: 500;
  background-color: white;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  position: relative;


  @media (max-width: 900px){
    padding: 0;
    font-size: 13px;
    width: 100%;
  }
}

/* .podiumNumber:nth-child(1) {
  border: 8px solid  #66C6B9;
  color: #66C6B9;
}
.podiumNumber:nth-child(2) {
  border: 8px solid  #FFD15C;
  color: #FFD15C;
}
.podiumNumber:nth-child(3) {
  border: 8px solid  #F3705A;
  color: #F3705A;
} */


div.spnBoxQuestions:hover {
  cursor: grab;
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

div.spnMobileBoxQuestions:hover {
  cursor: grab;
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
}


/* TOGGLE STYLE */

.spnRequiredScale {
  display: block;
  color: red;
  font-size: 13px;
  margin: 0 5px 8px;
}

.spnRequiredDragDrop {
  display: block;
  color: red;
  font-size: 13px;
  margin: 0 20px;
}

/* .spnRequiredDragDrop {
  display: none;
} */

.spnMobileBoxQuestions {
  background-color: #64748C;
  color: #fff;
  box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  -moz-box-shadow: 0 0 5px #999;
  padding: 13px;
  margin: 10px;
  transition: transform .2s;
  -webkit-transition: transform .2s;
  -moz-transition: transform .2s;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  text-align: center;
  overflow-wrap: break-word;
}

.spnBoxQuestions {
  background-color: #64748C;
  color: #fff;
  box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  -moz-box-shadow: 0 0 5px #999;
  padding: 14px;
  margin: 15px;
  transition: transform .2s;
  -webkit-transition: transform .2s;
  -moz-transition: transform .2s;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  text-align: center;
  width: 14vw;
  overflow-wrap: break-word;

  @media (max-width: 900px){
    width: 17vh;
    font-size: 1.9vh;
    margin: 0.8vh;
  }
}

.boxRequired {
  border: 4px solid red;
  border-top: 0;
  border-radius: 0 0 16px 16px;
}

.boxTitleRequired {
  border: 4px solid red;
  border-bottom: 0;
  border-radius: 16px 16px 0 0;
}

.exclamationMark {
  position: absolute;
  right: -21px;
  top: 14px;
  padding: 10px 5px;
  background-color: white;
  border-radius: 50%;
  border: 6px solid #ff3838;
  width: 50px;
  height: 50px;
  line-height: 23px;
  z-index: 1;
  color: #ff3838;
  font-weight: bold;
  font-size: 42px;
  /* transition: 0.5s; */
}
