.br-theme-bars-pill .br-widget {
  white-space: nowrap;
}
.br-theme-bars-pill .br-widget a {
  padding: 7px 15px;
  background-color: #bef5e8;
  /* color: #50E3C2; */
  color: #4D4646;
  text-decoration: none;
  font-size: 13px;
  line-height: 3;
  text-align: center;
  font-weight: 400;
}
.br-theme-bars-pill .br-widget a:first-child {
  -webkit-border-top-left-radius: 999px;
  -webkit-border-bottom-left-radius: 999px;
  -moz-border-radius-topleft: 999px;
  -moz-border-radius-bottomleft: 999px;
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
}
.br-theme-bars-pill .br-widget a:last-child {
  -webkit-border-top-right-radius: 999px;
  -webkit-border-bottom-right-radius: 999px;
  -moz-border-radius-topright: 999px;
  -moz-border-radius-bottomright: 999px;
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
}
.br-theme-bars-pill .br-widget a.br-active,
.br-theme-bars-pill .br-widget a.br-selected {
  background-color: #50E3C2;
  color: white;
}
.br-theme-bars-pill .br-readonly a {
  cursor: default;
}
.br-theme-bars-pill .br-readonly a.br-active,
.br-theme-bars-pill .br-readonly a.br-selected {
  background-color: #7cead1;
}
@media print {
  .br-theme-bars-pill .br-widget a {
    border: 1px solid #b3b3b3;
    border-left: none;
    background: white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .br-theme-bars-pill .br-widget a.br-active,
  .br-theme-bars-pill .br-widget a.br-selected {
    border: 1px solid black;
    border-left: none;
    background: white;
    color: black;
  }
  .br-theme-bars-pill .br-widget a:first-child {
    border-left: 1px solid black;
  }
}
