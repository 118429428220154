.br-theme-bars-movie .br-widget {
  height: 10px;
  white-space: nowrap;
}
.br-theme-bars-movie .br-widget a {
  display: block;
  text-align: center;
  width: 130px;
  height: 25px;
  float: left;
  background-color: #bbcefb;
  margin: 1px;
  color: rgb(70, 71, 71);
}
.br-theme-bars-movie .br-widget a.br-active,
.br-theme-bars-movie .br-widget a.br-selected {
  background-color: #4278F5;
  color: rgb(206, 206, 206);
}
.br-theme-bars-movie .br-widget .br-current-rating {
  clear: both;
  width: 240px;
  text-align: left;

  font-weight: 600;
  display: block;
  padding: .5em 0;
  color: #4278F5!important;
  font-weight: 400;
}
.br-theme-bars-movie .br-readonly a {
  cursor: default;
  color: rgb(70, 71, 71);
}
.br-theme-bars-movie .br-readonly a.br-active,
.br-theme-bars-movie .br-readonly a.br-selected {
  background-color: #729bf8;
  color: rgb(191, 209, 209);
}
.br-theme-bars-movie .br-readonly .br-current-rating {
  color: #729bf8;
}
@media print {
  .br-theme-bars-movie .br-widget a {
    border: 1px solid #b3b3b3;
    background: white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: rgb(206, 206, 206);
  }
  .br-theme-bars-movie .br-widget a.br-active,
  .br-theme-bars-movie .br-widget a.br-selected {
    border: 1px solid black;
    background: white;
    color: rgb(206, 206, 206);
  }
  .br-theme-bars-movie .br-widget .br-current-rating {
    color: black;
  }
}
