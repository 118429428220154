.br-theme-css-stars .br-widget {
  height: 55px;
  white-space: nowrap;
}
.br-theme-css-stars .br-widget a {
  text-decoration: none;
  height: 40px;
  width: 40px;
  float: left;
  font-size: 55px;
  margin-right: 5px;
}
.br-theme-css-stars .br-widget a:after {
  content: "\2605";
  color: #d2d2d2;
}

.br-theme-css-stars .br-widget a[data-rating-value="none"] {
  text-align: center;
}
.br-theme-css-stars .br-widget a[data-rating-value="none"]:after {
  content: "\20E0";
  color: #d2d2d2;
  font-size: 45px;
  text-align: center;
  line-height: 30px;
}

.br-theme-css-stars .br-widget a[data-rating-value="6"] {
  text-align: center;
}
.br-theme-css-stars .br-widget a[data-rating-value="6"]:after {
  content: "\20E0";
  color: #d2d2d2;
  font-size: 45px;
  text-align: center;
  line-height: 30px;
}

.br-theme-css-stars .br-widget a.br-active:after {
  color: #EDB867;
}

.br-theme-css-stars .br-widget a.br-selected:after {
  color: #EDB867;
}

.br-theme-css-stars .br-widget .br-current-rating {
  display: none;
}

.br-theme-css-stars .br-readonly a {
  cursor: default;
}

@media print {
  .br-theme-css-stars .br-widget a:after {
    content: "\2606";
    color: black;
  }
  .br-theme-css-stars .br-widget a.br-active:after,
  .br-theme-css-stars .br-widget a.br-selected:after {
    content: "\2605";
    color: black;
  }
}
